
import request from '@/api/request.js'

// 登录
export function login() {
  return request({
    url: 'oauth2/token/get', 
    method: 'get',
    params: {
      appId: 'silverdawn-work-website',
      secretKey: 'AcsoqgK8rnv3ij9isee93hSmteTE34s9'
    }
  })
}